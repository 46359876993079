import { set } from 'shades';
import { QUES_TYPE } from '../../../shared/consts/quesType';
import {
	MultipleChoiceResponseState,
	LinearRangeResponseState,
	OpinionScaleResponseState,
	NumberResponseState,
	RatingResponseState,
	YesNoResponseState,
	CreativeResponseState,
	MultipleOpinionScaleResponseState,
	MatrixResponseState,
	VideoUploadResponseState,
	TextBoxResponseState,
	MatrixMatchResponseState,
	ScreenerResponseState,
	ImageResponseState,
	TimeZoneResponseState,
	ModerateSlotSelectResponseState,
	ModerateSlotUserResponseState,
	MatrixMatchResponseType,
	EmailResponseState,
	RankingResponseState,
} from '../../../shared/types/ResponseTypes';
import {
	SET_SURVEY_RESPONSE,
	SETUP_FORM,
	SET_CREATIVE_RESPONSE,
	SET_CURR_QUES_INDEX,
	CREATE_SKIPPED,
	DELETE_SKIPPED,
	SET_LINEARRANGE_RESPONSE,
	SET_MATRIX_RESPONSE,
	SET_MULTIPLE_CHOICE_RESPONSE,
	SET_MULTIPLE_OPINION_SCALE_RESPONSE,
	SET_NUMBER_RESPONSE,
	SET_TEXT_BOX_RESPONSE,
	SET_OPINION_SCALE_RESPONSE,
	SET_RATING_RESPONSE,
	SET_YES_NO_RESPONSE,
	SET_VIDEO_UPLOAD_RESPONSE,
	SurveyResponseReducer,
	SurveyResponseState,
	SET_MATRIX_MATCH_RESPONSE,
	SET_SCREENER_RESPONSE,
	SET_IMAGE_QUES_RESPONSE,
	SET_TIMEZONE_RESPONSE,
	SET_TIMESLOT_MODERATE,
	SET_TIMESLOT_USER_DETAIL,
	SET_EMAIL_RESPONSE,
	SET_RANKING_RESPONSE,
} from './types';
import { getUtcDateIsoString } from '../../../shared/utils/getUtcDateIsoString';

const initialSurveyResponseState: SurveyResponseState = {
	responses: {},
	quesOrder: [],
	skipped: [],
	currQuesIndex: 0,
	startTime: '',
};

export const surveyResponseReducer: SurveyResponseReducer = (state = initialSurveyResponseState, action) => {
	switch (action.type) {
		case SET_SURVEY_RESPONSE: {
			return action.payload;
		}

		case SETUP_FORM: {
			const { responses, quesOrder, clearSkipped } = action.payload;

			return {
				...state,
				responses,
				quesOrder,
				skipped: clearSkipped ? [] : state.skipped,
				startTime: getUtcDateIsoString(),
			};
		}

		case SET_CURR_QUES_INDEX: {
			const { index } = action.payload;

			if (index >= state.quesOrder.length) return state;

			return {
				...state,
				currQuesIndex: index,
			};
		}

		case CREATE_SKIPPED: {
			const { quesId } = action.payload;
			return {
				...state,
				skipped: [...state.skipped, quesId],
			};
		}

		case DELETE_SKIPPED: {
			const { quesId } = action.payload;

			return {
				...state,
				skipped: state.skipped.filter((id) => id !== quesId),
			};
		}

		case SET_OPINION_SCALE_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.OPINION_SCALE_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<OpinionScaleResponseState>);
		}

		case SET_MULTIPLE_CHOICE_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.MULTIPLE_CHOICE_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<MultipleChoiceResponseState>);
		}
		case SET_RANKING_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.RANKING_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<RankingResponseState>);
		}

		case SET_MATRIX_MATCH_RESPONSE: {
			const {
				quesId,
				response,
				settings: { ranking },
			} = action.payload;
			const filteredResponse: MatrixMatchResponseType = response.reduce((acc, columnOptions, index, array): any => {
				const findIndex = array.map((opt) => opt.columnOrderPosition).lastIndexOf(columnOptions.columnOrderPosition);
				if (findIndex >= 0) {
					if (!acc.map((ele) => JSON.stringify(ele)).includes(JSON.stringify(array[findIndex])))
						//@ts-ignore
						acc.push(array[findIndex]);
				}
				return acc;
			}, []);
			if (state.responses[quesId]?.type !== QUES_TYPE.MATRIX_MATCH_QUES) return { ...state };
			return set('responses', quesId, 'response')(!ranking ? response : filteredResponse)(
				state as SurveyResponseState<MatrixMatchResponseState>
			);
		}

		case SET_NUMBER_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId]?.type !== QUES_TYPE.NUMBER_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<NumberResponseState>);
		}

		case SET_TEXT_BOX_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId]?.type !== QUES_TYPE.TEXT_BOX_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<TextBoxResponseState>);
		}

		case SET_EMAIL_RESPONSE: {
			const { quesId, response } = action.payload;
			if (state.responses[quesId]?.type !== QUES_TYPE.EMAIL_QUES) return { ...state };
			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<EmailResponseState>);
		}

		case SET_RATING_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId]?.type !== QUES_TYPE.RATING_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<RatingResponseState>);
		}

		case SET_YES_NO_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.YES_NO_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<YesNoResponseState>);
		}

		case SET_VIDEO_UPLOAD_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.VIDEO_UPLOAD_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<VideoUploadResponseState>);
		}

		case SET_CREATIVE_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.CREATIVE_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<CreativeResponseState>);
		}

		case SET_MULTIPLE_OPINION_SCALE_RESPONSE: {
			const { quesId, sectionId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES) return { ...state };

			return set('responses', quesId, 'response', sectionId)(response)(
				state as SurveyResponseState<MultipleOpinionScaleResponseState>
			);
		}

		case SET_MATRIX_RESPONSE: {
			const { quesId, response, columnId } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.MATRIX_QUES) return { ...state };

			return set('responses', quesId, 'response', columnId)(response)(
				state as SurveyResponseState<MatrixResponseState>
			);
		}

		case SET_LINEARRANGE_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.LINEAR_RANGE_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<LinearRangeResponseState>);
		}

		case SET_SCREENER_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId].type !== QUES_TYPE.SCREENER_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<ScreenerResponseState>);
		}

		case SET_IMAGE_QUES_RESPONSE: {
			const { quesId, response } = action.payload;

			if (state.responses[quesId]?.type !== QUES_TYPE.IMAGE_QUES) return { ...state };

			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<ImageResponseState>);
		}

		case SET_TIMEZONE_RESPONSE: {
			const { quesId, response } = action.payload;
			return set('responses', quesId, 'response')(response)(state as SurveyResponseState<TimeZoneResponseState>);
		}

		case SET_TIMESLOT_MODERATE: {
			const { quesId, response } = action.payload;
			return set('responses', quesId, 'response')(response)(
				state as SurveyResponseState<ModerateSlotSelectResponseState>
			);
		}

		case SET_TIMESLOT_USER_DETAIL: {
			const { quesId, response } = action.payload;
			return set('responses', quesId, 'response')(response)(
				state as SurveyResponseState<ModerateSlotUserResponseState>
			);
		}
		default: {
			return state;
		}
	}
};
